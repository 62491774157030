@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Noto+Sans:wght@400;700&display=swap');

/* body {
  font-family: 'Inter'; sans-serif;
  font-family: 'Noto Sans', sans-serif;
} */

.Mui-error {
  font-size: 12px;
}

a {
  text-decoration: none;
}

.flex-row-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dflex {
  display: flex;
}

/* MAP STYLES */
.autocomplete-dropdown-container {
  position: absolute;
  background: white;
  z-index: 999;
  width: 100%;
}
.autocomplete-container {
  background-color: white;
  position: relative;
}

.gm-style-cc {
  display: none;
}

.location-search-input {
  width: 95%;
  margin: 10px auto 0px;
  border: 1px solid #d1d5db;
  padding: 10px;
  font-family: 'Inter';
  font-size: 14px;
  color: #000;
  border-radius: 6px;
  padding-left: 35px;
}
.location-search-input:focus-visible {
  outline: none;
}

.autocomplete-container {
  text-align: center;
  color: #000;
}

.suggestion-item {
  text-align: left;
  margin: 5px 20px;
}
.suggestion-item--active {
  text-align: left;
  margin: 5px 20px;
}

.map {
  border-radius: 8px;
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-simple {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.PrivatePickersToolbar-dateTitleContainer > .MuiIconButton-sizeMedium {
  display: none;
}

#menu-hour .MuiList-root {
  max-height: 300px;
  overflow-y: scroll;
}

.MuiButton-contained.Mui-disabled {
  background: #1870c7 !important;
  opacity: 0.4 !important;
  color: #ffffff !important;
}
.MuiLoadingButton-root:hover:hover {
  box-shadow: none;
}
