.swal2-popup {
  /* width: 394px !important; */
  padding-bottom: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.swal2-container {
  z-index: 9999 !important;
}

.swal2-actions {
  margin-top: 0 !important;
  flex-direction: row-reverse;
}

.swal2-styled {
  padding: 1rem 1rem !important;
  margin: 0.3125em 12px !important;
}
.swal2-html-container > h4 {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 2px;
}
.swal2-html-container > p {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}
.swal2-confirm {
  font-family: Inter !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;

  width: 156px;
  border-radius: 4px !important;
  border: none !important;
}
.swal2-cancel {
  color: #374151 !important;
  border: 1px solid rgba(55, 65, 81, 0.5) !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  width: 156px;
  border-radius: 4px !important;
}

.swal2-show {
  -webkit-animation: none !important;
  animation: none !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%) !important;
}

.swal2-input .swal2-span {
  font-size: '10px';
}
